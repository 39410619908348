import "slick-carousel/slick/slick.css";
import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as ArrowLeftIcon} from "images/arrow-left-3-icon.svg";
import {ReactComponent as ArrowRightIcon} from "images/arrow-right-3-icon.svg";

const Row = tw.div`flex flex-col md:flex-row justify-between md:px-16`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const PartSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left mb-3`}
  .slick-track {
    ${tw`flex`}
  }

  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Partner = tw.div`outline-none h-full flex! flex-col`;
const PartnerImgWrapper = tw.div`h-56 w-full flex items-center justify-center`;
const PartnerImg = tw.img`object-contain w-full h-full p-3`;
const PartnerName = tw.h3`text-xl w-full text-center`;
const PartnerDescription = styled.div`
  ${tw`pt-8 font-light`}
  ${props =>
          css`
            h2 {
              ${tw`text-purple-classic text-3xl`}
            }

            h3 {
              ${tw`text-purple-classic text-2xl`}
            }

            a {
              ${tw`text-purple-classic hover:text-purple-pastel`}
            }

            strong {
              ${tw`font-medium`}
            }

            blockquote {
              ${tw`italic pl-3 border-l-2 text-gray-800 mb-1`}
            }
          `}
`;


const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0 items-end ml-auto mr-0 justify-center`}
`;

const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 hover:bg-purple-pastel text-purple-pastel hover:text-white focus:outline-none border-solid border border-purple-pastel`}
  svg {
    ${tw`w-4 h-4 stroke-2`}
  }
`;

export default ({partners = []}) => {
    const [sliderRef, setSliderRef] = useState(null);

    return (
        <Row>
            <Column>
                <PartSlider
                    arrows={false}
                    ref={setSliderRef}
                    slidesToScroll={1}
                    autoplay={true}
                    autoplaySpeed={3000}
                    pauseOnHover={true}
                >
                    {partners.map((partner, index) => (
                        <Partner key={index}>
                            <PartnerImgWrapper>
                                <PartnerImg src={partner.image} alt={"Logo"}/>
                            </PartnerImgWrapper>
                            <PartnerName>{partner.name}</PartnerName>
                            <PartnerDescription dangerouslySetInnerHTML={{__html: partner.text}}/>
                        </Partner>
                    ))}
                </PartSlider>
                <Controls>
                    <ControlButton onClick={sliderRef?.slickPrev}>
                        <ArrowLeftIcon/>
                    </ControlButton>
                    <ControlButton onClick={sliderRef?.slickNext}>
                        <ArrowRightIcon/>
                    </ControlButton>
                </Controls>
            </Column>
        </Row>
    );
};
