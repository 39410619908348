import React from "react";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import Header from "./common/Header";
import styled from "styled-components";
import {PrimaryButton as PrimaryButtonBase} from "../components/misc/Buttons";
import PictoB2App from "../images/PICTOS_IRIS_APPLICATION.png";
import PictoB2Comm from "../images/PICTOS_IRIS_COMMUNAUTE.png";
import PictoB2Mag from "../images/PICTOS_IRIS_MAGAZINE.png";
import PictoB2Web from "../images/PICTOS_IRIS_WEBSITE.png";
import InteractImage from "../images/app_iris_interact.png";
import PhoneSteps from "../images/magiris-visuels_19.png";
import AppIris from "../images/magiris-home_01.png";
import TestimonialImage from "../images/IRIS_temoignages.jpg";
import RondVertImage from "../images/rond-vert.png";
import {SectionDescription} from "../components/misc/Typography";
import {SectionHeading} from "../components/misc/Headings";
import {ContentWithPaddingXl} from "../components/misc/Layouts";
import {withTranslation} from "react-i18next";
import Constants from "../constants";
import * as axios from "axios";
import {getLanguage} from "./common/languages";
import i18next from "i18next";
import {animateScroll as scroll, Element, Link} from "react-scroll";
import Footer from "./common/Footer";
import GreenPoint from "./common/GreenPoint";
import SocialNetworks from "./common/SocialNetworks";
import {getImageUrl} from "../utils";
import hwImg1 from "../images/magiris-home_icon1.png";
import hwImg2 from "../images/magiris-home_icon2.png";
import hwImg3 from "../images/magiris-home_icon3.png";
import hwImg4 from "../images/magiris-home_icon4.png";
import Formulas from "../components/Formulas";
import Redirect from "react-router-dom/es/Redirect";
import ModalVideo from "react-modal-video";
import FlipBook from "../components/viewer/FlipBook";
import PartnerSlider from "../components/PartnerSlider";
import PopupRecrutement from "./common/PopupRecrutement";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center lg:ml-40 mr-0 py-10 md:py-10 md:px-5`;
const TwoColumnWithPaddingX = tw.div`flex flex-col lg:flex-row lg:items-center lg:ml-40 mr-0 py-10 md:py-10`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left pr-3`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-pastel leading-tight italic`;
const Paragraph = tw.p`my-5 lg:my-8 text-base text-black xl:text-lg font-light`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0 font-medium`
]);

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const VerticalSpacer = tw.div`mt-10 w-full`
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2 max-w-lg`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`text-center flex-shrink-0`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-medium text-2xl leading-none text-black`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-normal text-gray-600 leading-loose text-black opacity-75`}
  }
`;

const ColumnHW = styled.div`
  ${tw`md:w-1/4 lg:w-1/4 max-w-lg items-center justify-center flex`}
`;

const CardHW = tw.div`flex flex-col justify-center items-center`;
const CardHWImage = tw.img`w-32 md:w-40 mb-3 mt-12 md:mt-0`;
const CardTextHW = styled.div`
  ${tw`text-center text-lg`}
  .bold {
    ${tw`font-bold`}
  }

`;

const Subheading = tw.span`uppercase tracking-widest font-medium text-green-classic`;

const SubheadingB2 = tw(Subheading)`mb-4`;
const HeadingBIS = tw(SectionHeading)`w-full italic`;
const DescriptionB2 = tw(SectionDescription)`w-full text-center font-light`;

const ColumnB2 = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(ColumnB2)`md:w-4/12 flex-shrink-0 relative flex justify-center items-center`;
const ImageColumnB3 = tw(ColumnB2)`md:w-6/12 flex-shrink-0 relative flex items-center`;
const TextColumn = styled(ColumnB2)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
    props.clickEnabled && tw`cursor-pointer`,
    props.imageRounded && tw`rounded-full`,
    tw`w-128`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-medium text-4xl leading-none text-green-classic`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepDescription = tw.p`max-w-xs leading-loose text-base text-black opacity-75`;
const ImageB4 = styled.img(props => [
    tw`w-128 `
]);

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;

const RondVert = tw.img`w-128 absolute left-20`;
const ButtonCenter = tw.div`flex mt-5 justify-center`;

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            opinion: [],
            partners: [],
            isOnTop: true,
            redirect: "",
            isVideoOpen: false,
            popupIsClosed: Date.now() > Date.parse("2021-09-20")
        }

        i18next.on('languageChanged', () => this.loadOpinion());

        const {t} = this.props;

        this.hw = [
            {
                img: hwImg1,
                text: t("HOME_HW_S1")
            },
            {
                img: hwImg2,
                text: t("HOME_HW_S2")
            },
            {
                img: hwImg3,
                text: t("HOME_HW_S3")
            },
            {
                img: hwImg4,
                text: t("HOME_HW_S4")
            }
        ];

        this.cards = [
            {
                imageSrc: PictoB2Mag,
                title: t("HOME_B2_S1_TITLE"),
                description: t("HOME_B2_S1_DESC")
            },
            {
                imageSrc: PictoB2App,
                title: t("HOME_B2_S2_TITLE"),
                description: t("HOME_B2_S2_DESC")
            },
            {
                imageSrc: PictoB2Web,
                title: t("HOME_B2_S3_TITLE"),
                description: t("HOME_B2_S3_DESC"),
            },
            {
                imageSrc: PictoB2Comm,
                title: t("HOME_B2_S4_TITLE"),
                description: t("HOME_B2_S4_DESC"),
            }
        ];

        this.steps = [
            t("HOME_B4_S1"),
            t("HOME_B4_S2"),
            t("HOME_B4_S3"),
        ];

        this.faq = [
            {question: t("HOME_B8_Q1"), answer: t("HOME_B8_A1"),},
            {question: t("HOME_B8_Q2"), answer: t("HOME_B8_A2"),},
            {question: t("HOME_B8_Q3"), answer: t("HOME_B8_A3"),},
            {question: t("HOME_B8_Q4"), answer: t("HOME_B8_A4"),},
            {question: t("HOME_B8_Q5"), answer: t("HOME_B8_A5"),},
            {question: t("HOME_B8_Q6"), answer: t("HOME_B8_A6"),},
            {question: t("HOME_B8_Q7"), answer: t("HOME_B8_A7"),},
            {question: t("HOME_B8_Q8"), answer: t("HOME_B8_A8"),},
            {question: t("HOME_B8_Q9"), answer: t("HOME_B8_A9"),},
            {question: t("HOME_B8_Q10"), answer: t("HOME_B8_A10"),},
        ];
    }

    componentDidMount() {
        this.loadOpinion();
        this.loadPartners();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 120) {
            this.setState({isOnTop: false});
        } else {
            this.setState({isOnTop: true});
        }
    }

    loadOpinion() {

        axios.get(Constants.baseCmsUrl + Constants.opinionRoute + "?lang=" + getLanguage().toUpperCase())
            .then((response) => {
                const opinions = response.data.map(opinion => {
                    return {
                        stars: opinion.stars,
                        profileImageSrc: getImageUrl(opinion.avatar, 'small'),
                        heading: opinion.title,
                        quote: opinion.content,
                        customerName: opinion.name,
                    }
                });

                this.setState({opinions: opinions});
            })
            .catch(err => console.error(err));
    }

    loadPartners() {

        axios.get(Constants.baseCmsUrl + Constants.partnersRoute)
            .then((response) => {
                const partners = response.data.map(partner => {
                    return {
                        name: partner.name,
                        text: partner.text,
                        image: getImageUrl(partner.image, 'medium'),
                    }
                });

                this.setState({partners: partners});
            })
            .catch(err => console.error(err));
    }

    openSignup = () => {
        window.location.assign(Constants.privateAppUrl + "/signup");
    }

    openContact = () => {
        this.setState({
            redirect: "/contact"
        });
    }

    render() {

        const {t} = this.props;

        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <AnimationRevealPage>
                {this.state.popupIsClosed ? null : <PopupRecrutement
                    animation={false}
                    closePopup={() => this.setState({popupIsClosed: true})}
                />}
                <ModalVideo
                    autoplay={true}
                    channel='youtube'
                    isOpen={this.state.isVideoOpen}
                    videoId='VeLy22loeXg'
                    onClose={() => this.setState({isVideoOpen: false})}
                />
                <GreenPoint animation={false}/>
                <SocialNetworks showScrollButton={true} isOnTop={this.state.isOnTop} scrollTo="howWork"
                                animation={false}/>
                <Header roundedHeaderButton={true} animation={false}/>
                <Container animation={false}>
                    <TwoColumnWithPaddingX>
                        <LeftColumn>
                            <Heading>
                                {t("HOME_B1_TITLE")}
                            </Heading>
                            <Paragraph>
                                {t("HOME_B1_DESC")}
                            </Paragraph>
                            <PrimaryButton
                                onClick={this.openSignup}
                            >
                                {t("HOME_B1_BUTTON")}
                            </PrimaryButton>
                        </LeftColumn>
                        <RightColumn>
                            <IllustrationContainer>
                                <Link
                                    to={"magazine"}
                                    spy={true}
                                    smooth={true}
                                    offset={50}
                                    duration={800}>
                                    <img
                                        tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                                        src={AppIris}
                                        alt="Mag-iris"/>
                                </Link>
                            </IllustrationContainer>
                        </RightColumn>
                    </TwoColumnWithPaddingX>
                </Container>
                <Element name="howWork">
                    <Container>
                        <TwoColumnContainer>
                            <HeadingBIS>{t("HOME_HW_TITLE")}</HeadingBIS>
                            <DescriptionB2>{t("HOME_HW_SUBTITLE")}</DescriptionB2>
                            <VerticalSpacer/>
                            {this.hw.map((card, i) => (
                                <ColumnHW key={i}>
                                    <CardHW>
                                        <CardHWImage src={card.img} alt="Card"/>
                                        <CardTextHW dangerouslySetInnerHTML={{__html: card.text}}/>
                                    </CardHW>
                                </ColumnHW>
                            ))}
                            <PrimaryButton
                                className={"mt-5"}
                                onClick={this.openSignup}
                            >
                                {t("HOME_CTA_BUTTON_1")}
                            </PrimaryButton>
                        </TwoColumnContainer>
                    </Container>
                </Element>
                <Element name={"magazine"}>
                    <Container>
                        <ContentWithPaddingXl>
                            <HeaderContainer>
                                <Subheading>{t("HOME_SAMPLE_SUBTITLE")}</Subheading>
                                <HeadingBIS>{t("HOME_SAMPLE_TITLE")}</HeadingBIS>
                                <Paragraph>{t("HOME_SAMPLE_DESC")}</Paragraph>
                            </HeaderContainer>
                            <FlipBook/>
                            <ButtonCenter>
                                <PrimaryButton
                                    onClick={this.openSignup}
                                >
                                    {t("HOME_CTA_BUTTON_2")}
                                </PrimaryButton>
                            </ButtonCenter>
                        </ContentWithPaddingXl>
                    </Container>
                </Element>
                <Element name="b2">
                    <Container>
                        <TwoColumnContainer>
                            <HeadingBIS>{t("HOME_B2_TITLE")}</HeadingBIS>
                            <DescriptionB2>{t("HOME_B2_DESC")}</DescriptionB2>
                            <VerticalSpacer/>
                            {this.cards.map((card, i) => (
                                <Column key={i}>
                                    <Card>
                                      <span className="imageContainer">
                                        <img src={card.imageSrc} alt=""/>
                                      </span>
                                        <span className="textContainer">
                                        <span className="title">{card.title || "Fully Secure"}</span>
                                        <p className="description">
                                          {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                                        </p>
                                      </span>
                                    </Card>
                                </Column>
                            ))}
                            <PrimaryButton
                                className={"mt-5"}
                                onClick={this.openSignup}
                            >
                                {t("HOME_CTA_BUTTON_2")}
                            </PrimaryButton>
                        </TwoColumnContainer>
                    </Container>
                </Element>
                <Container>
                    <TwoColumn>
                        <ImageColumnB3>
                            <Image
                                src={InteractImage}
                                imageRounded={true}
                                clickEnabled={true}
                                onClick={() => this.setState({isVideoOpen: true})}
                            />
                        </ImageColumnB3>
                        <TextColumn textOnLeft={true}>
                            <TextContent>
                                <Subheading>{t("HOME_B3_SUBTITLE")}</Subheading>
                                <Heading>{t("HOME_B3_TITLE")}</Heading>
                                <Paragraph>{t("HOME_B3_DESC")}</Paragraph>
                                <PrimaryButton
                                    className={"mt-5"}
                                    onClick={this.openSignup}
                                >
                                    {t("HOME_CTA_BUTTON_3")}
                                </PrimaryButton>
                            </TextContent>
                        </TextColumn>

                    </TwoColumn>
                </Container>
                <Container>
                    <RondVert src={RondVertImage} style={{zIndex: -2}}/>
                    <TwoColumn>
                        <ImageColumn>
                            <ImageB4 src={PhoneSteps} imageRounded={false}/>
                        </ImageColumn>
                        <TextColumn textOnLeft={false}>
                            <TextContent>
                                <Subheading>{t("HOME_B4_SUBTITLE")}</Subheading>
                                <Heading>{t("HOME_B4_TITLE")}</Heading>
                                <Steps>
                                    {this.steps.map((step, index) => (
                                        <Step key={index}>
                                            <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                                            <StepText>
                                                <StepDescription>{step}</StepDescription>
                                            </StepText>
                                        </Step>
                                    ))}
                                </Steps>
                            </TextContent>
                            <PrimaryButton
                                className={"mt-5"}
                                onClick={this.openSignup}
                            >
                                {t("HOME_CTA_BUTTON_2")}
                            </PrimaryButton>
                        </TextColumn>
                    </TwoColumn>
                </Container>
                <Container>
                    <ContentWithPaddingXl>
                        <HeaderContainer>
                            <Subheading>{t("HOME_B5_SUBTITLE")}</Subheading>
                            <HeadingBIS>{t("HOME_B5_TITLE")}</HeadingBIS>
                            <Paragraph>{t("HOME_B5_DESC")}</Paragraph>
                        </HeaderContainer>
                        <Formulas openSignup={this.openSignup} openContact={this.openContact}/>
                    </ContentWithPaddingXl>
                </Container>
                <Testimonial
                    imageSrc={TestimonialImage}
                    subheading={<Subheading>{t("HOME_B6_SUBTITLE")}</Subheading>}
                    heading={<Heading>{t("HOME_B6_TITLE")}</Heading>}
                    testimonials={this.state.opinions}
                />
                <FAQ
                    subheading={<Subheading>{t("HOME_B7_SUBTITLE")}</Subheading>}
                    heading={<HeadingBIS>{t("HOME_B7_TITLE")}</HeadingBIS>}
                    description={<Paragraph>{t("HOME_B7_DESC")}</Paragraph>}
                    faqs={this.faq}
                />
                {
                    this.state.partners !== undefined && this.state.partners.length > 0 ?
                        <Container>
                            <ContentWithPaddingXl>
                                <HeaderContainer>
                                    <Subheading>{t("HOME_PARTNERS_SUBTITLE")}</Subheading>
                                    <HeadingBIS>{t("HOME_PARTNERS_TITLE")}</HeadingBIS>
                                    <Paragraph>{t("HOME_PARTNERS_DESC")}</Paragraph>
                                </HeaderContainer>
                                <PartnerSlider
                                    partners={this.state.partners}
                                />
                            </ContentWithPaddingXl>
                        </Container> : <div/>
                }
                <Footer animation={false}/>
            </AnimationRevealPage>
        );
    }
}

export default withTranslation()(Index)
