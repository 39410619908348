import React from "react";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";
import {ReactComponent as CloseIcon} from "feather-icons/dist/icons/x.svg";
import pdfIcon from "../../images/pdf-icon.png";
import hiring from "../../images/hiring.png";

const Container = tw.div`mt--5 ml--5 lg:ml-0`;
const Wrapper = tw.div`fixed z-30 mt--5 w-full`;
const Overlay = tw.div`fixed bg-black z-30 w-full h-full opacity-90`;
const ContentWrapper = tw.div`w-screen z-40 h-screen p-12 lg:p-32 flex flex-col items-center justify-center`;
const CloseWrapper = tw.div`absolute top-0 right-0 text-white w-24 h-24 z-50 mt-10`;
const Title = tw.h1`text-white text-2xl`;
const FileWrapper = tw.div`flex mt-16`;
const File = tw.a`items-center justify-center text-center flex flex-col text-white mx-3 text-sm font-light`;

class PopupRecrutement extends React.Component {

    render() {

        return (
            <Container>
                <Overlay/>
                <Wrapper>
                    <CloseWrapper>
                        <CloseIcon style={{height: 50, width: 50}} onClick={() => this.props.closePopup()}/>
                    </CloseWrapper>
                    <ContentWrapper>
                        <img src={hiring} style={{height: 150}}/>
                        <Title>JOB D’ÉTÉ</Title>
                        <Title>Mag-iris recrute</Title>
                        <FileWrapper>
                            <File
                                href={"https://storage.uk.cloud.ovh.net/v1/AUTH_d6ecc36796994e85a3c49282a52fa999/magiris-save-prod/LIGILO_Description_Poste_1.pdf"}
                                target={"_blank"}>
                                <img src={pdfIcon} style={{height: 80, marginBottom: 10}}/>
                                <h3>Prospecteur.trice.s</h3>
                            </File>
                            <File
                                href={"https://storage.uk.cloud.ovh.net/v1/AUTH_d6ecc36796994e85a3c49282a52fa999/magiris-save-prod/LIGILO_Description_Poste_2.pdf"}
                                target={"_blank"}>
                                <img src={pdfIcon} style={{height: 80, marginBottom: 10}}/>
                                <h3>Responsable d’équipe</h3>
                            </File>
                        </FileWrapper>
                    </ContentWrapper>
                </Wrapper>

            </Container>

        )
    }
}

export default withTranslation()(PopupRecrutement);
