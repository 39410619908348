import React from "react";
import HTMLFlipBook from "react-pageflip";
import tw from "twin.macro";
import {withTranslation} from "react-i18next";
import {ReactComponent as ArrowRight} from "feather-icons/dist/icons/arrow-right.svg";
import {ReactComponent as ArrowLeft} from "feather-icons/dist/icons/arrow-left.svg";

import page01 from "../../images/magazine/01.jpg";
import page02 from "../../images/magazine/02.jpg";
import page03 from "../../images/magazine/03.jpg";
import page04 from "../../images/magazine/04.jpg";
import page05 from "../../images/magazine/05.jpg";
import page06 from "../../images/magazine/06.jpg";
import page07 from "../../images/magazine/07.jpg";
import page08 from "../../images/magazine/08.jpg";
import page09 from "../../images/magazine/09.jpg";
import page10 from "../../images/magazine/10.jpg";
import page11 from "../../images/magazine/11.jpg";
import page12 from "../../images/magazine/12.jpg";
import page13 from "../../images/magazine/13.jpg";
import page14 from "../../images/magazine/14.jpg";
import page15 from "../../images/magazine/15.jpg";
import page16 from "../../images/magazine/16.jpg";
import page17 from "../../images/magazine/17.jpg";
import page18 from "../../images/magazine/18.jpg";
import page19 from "../../images/magazine/19.jpg";
import page20 from "../../images/magazine/20.jpg";


const Container = tw.div`bg-green-pastel shadow-2xl`;
const ButtonContainer = tw.div`flex mt-5 justify-center`;
const IconWrapper = tw.div`ml-2 mr-2 bg-green-pastel p-3 text-purple-pastel rounded-full font-bold`;

class FlipBook extends React.Component {

    nextButtonClick = () => {
        this.flipBook.getPageFlip().flipNext();
    };

    prevButtonClick = () => {
        this.flipBook.getPageFlip().flipPrev();
    };

    render() {

        const {t} = this.props;

        return (
            <div>
                <Container>
                    <HTMLFlipBook
                        width={550}
                        height={733}
                        size="stretch"
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1533}
                        maxShadowOpacity={1}
                        showCover={true}
                        mobileScrollSupport={true}
                        ref={(el) => (this.flipBook = el)}
                        onFlip={(e) => console.log(e.data)}
                    >

                        <img src={page01} alt={"Magazine page 1"}/>
                        <img src={page02} alt={"Magazine page 2"}/>
                        <img src={page03} alt={"Magazine page 3"}/>
                        <img src={page04} alt={"Magazine page 4"}/>
                        <img src={page05} alt={"Magazine page 5"}/>
                        <img src={page06} alt={"Magazine page 6"}/>
                        <img src={page07} alt={"Magazine page 7"}/>
                        <img src={page08} alt={"Magazine page 8"}/>
                        <img src={page09} alt={"Magazine page 9"}/>
                        <img src={page10} alt={"Magazine page 10"}/>
                        <img src={page11} alt={"Magazine page 11"}/>
                        <img src={page12} alt={"Magazine page 12"}/>
                        <img src={page13} alt={"Magazine page 13"}/>
                        <img src={page14} alt={"Magazine page 14"}/>
                        <img src={page15} alt={"Magazine page 15"}/>
                        <img src={page16} alt={"Magazine page 16"}/>
                        <img src={page17} alt={"Magazine page 17"}/>
                        <img src={page18} alt={"Magazine page 18"}/>
                        <img src={page19} alt={"Magazine page 19"}/>
                        <img src={page20} alt={"Magazine page 20"}/>
                    </HTMLFlipBook>
                </Container>
                <ButtonContainer>
                    <IconWrapper>
                        <ArrowLeft onClick={this.prevButtonClick}/>
                    </IconWrapper>
                    <IconWrapper>
                        <ArrowRight onClick={this.nextButtonClick}/>
                    </IconWrapper>
                </ButtonContainer>

            </div>
        )
    }
}

export default withTranslation()(FlipBook);
