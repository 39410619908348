import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "./common/Header";
import Footer from "./common/Footer";
import {withTranslation} from "react-i18next";
import GreenPoint from "./common/GreenPoint";
import SocialNetworks from "./common/SocialNetworks";

const HeadingRow = tw.div`flex`;
const Heading = tw.h1`font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-pastel leading-tight`;

const Title = tw.div`mt-1 font-black text-2xl text-black group-hover:text-purple-classic transition duration-300`;
const Description = tw.p``;

class CGV extends React.Component {

    render() {

        return (
            <AnimationRevealPage>
                <GreenPoint/>
                <SocialNetworks showScrollButton={false}/>
                <Header/>
                <Container>
                    <ContentWithPaddingXl>
                        <HeadingRow>
                            <Heading>Conditions générales de vente de mag-iris</Heading>
                        </HeadingRow>
                        <Description>
                            Ces Conditions générales de vente (ci-après «CGV») règlent le rapport contractuel entre la
                            société mag-iris, Onex-Genève en tant qu’exploitant de la plate-forme en ligne
                            «mag-iris.com» (ci-après «le site») et de l’application « mag-iris app » (ci-après «
                            l’application») et leurs utilisatrices et utilisateurs.<br/>
                            Sur le site et l’application, mag-iris offre aux utilisateurs la possibilité d’acheter des
                            publications imprimées (abonnements ou numéros individuels) et numériques (disponibles pour
                            les contributeurs du magazine). L’utilisation de du site et de l’application implique
                            l’acceptation des CGV par l’utilisateur.
                        </Description>
                        <Title>1. Champ d’application des CGV</Title>
                        <Description>
                            1.1 L’utilisation des site et application et l’achat de publications imprimées et
                            numériques (ensemble, ci-après, les «produits») ont lieu sur base de ces CGV.
                        </Description>
                        <Title>2. Statut juridique de mag-iris</Title>
                        <Description>
                            2.1 mag-iris exploite les site et application éponymes.
                        </Description>
                        <Title>3.Utilisation de www.mag-iris.com</Title>
                        <Description>
                            3.1 L’utilisation de la partie publique du site mag-iris.com peut avoir lieu avec ou sans
                            inscription.
                        </Description>
                        <Description>
                            3.2 Dès la validation de l’inscription, les utilisateurs inscrits ont accès à leur compte
                            utilisateur/profil utilisateur sur une partie dite privée du site.
                        </Description>
                        <Description>
                            3.3 Les personnes ayant atteint l’âge de 18 ans accomplis et domiciliées en Suisse peuvent
                            s’inscrire sur www.mag-iris.com. L’inscription doit avoir lieu sous le nom officiel. Le
                            domicile enregistré auprès des autorités doit être utilisé comme adresse. Les changements de
                            nom et/ou d’adresse doivent être effectués par Internet via le compte utilisateur personnel.
                        </Description>
                        <Description>
                            3.4 mag-iris est autorisée à refuser des inscriptions ou à désactiver des inscriptions
                            effectuées sans être tenue de fournir de raisons. L’utilisateur sera informé par courriel.
                        </Description>
                        <Description>
                            3.5 L’inscription peut être désactivée à tout moment en envoyant un courriel à l’adresse
                            contact@mag-iris.com. La désactivation du compte utilisateur n’a aucun effet sur les
                            livraisons non encore arrivées de produits/d’abonnements déjà payés dès lors qu’un autre
                            utilisateur reste administrateur du compte ou est désigné comme tel.
                        </Description>
                        <Title>4. Conclusion d’un contrat</Title>
                        <Description>
                            4.1 Sur mag-iris.com et mag-iris app, les produits peuvent être acquis via un paiement par
                            carte de paiement.
                        </Description>
                        <Title>5. Souscription de l'abonnement</Title>
                        <Description>
                            5.1 Le contrat d’abonnement est conclu par le client depuis la plateforme mag-iris.com, pour
                            la période indiquée dans l’offre souscrite.
                        </Description>
                        <Description>
                            5.1.1 Toute souscription d'abonnement vaut acceptation des présentes CGV.
                        </Description>
                        <Description>
                            5.2 Résiliation de l'abonnement
                        </Description>
                        <Description>
                            5.2.1 L’abonnement est automatiquement résilié en cas de non-paiement du numéro à venir.
                        </Description>
                        <Description>
                            5.2.2 La résiliation anticipée de l’abonnement n’est pas possible, sauf sous conditions
                            particulières dans un délai de trois mois.
                        </Description>
                        <Description>
                            5.3. Modification et cessation de la publication
                        </Description>
                        <Description>
                            5.3.1 mag-iris se réserve le droit de modifier la présentation ainsi que la mise en pages
                            des contenus proposés en tout temps.
                        </Description>
                        <Description>
                            5.3.2 Si, pour une raison quelconque, la publication devait ne plus paraître au cours de la
                            période d'abonnement, mag-iris peut proposer à l'utilisateur abonné une autre prestation
                            correspondant au solde en francs de l'abonnement. En cas de refus de la proposition,
                            mag-iris s'engage à rembourser l'utilisateur abonné pour la durée de l'abonnement restante.
                        </Description>
                        <Description>
                            5.3.3 En cas de changement d'éditeur, le présent contrat et les données en possession de «
                            mag-iris » relatives à l’utilisateur abonné peuvent être librement transférés afin de
                            garantir la continuité de la prestation convenue.
                        </Description>
                        <Description>
                            5.4. Conditions particulières
                        </Description>
                        <Description>
                            5.4.1 L'abonnement est souscrit par une personne physique, (ci-après : le client
                            administrateur) qui peut en faire un abonnement collectif en partageant les accès avec
                            d’autres personnes physiques (ci-après : les clients), qui peuvent tous s’acquitter du prix
                            de l’abonnement.
                        </Description>
                        <Description>
                            5.4.2. Lorsque l’abonnement est souscrit par une personne morale (EMS, municipalité),
                            celle-ci peut désigner un client administrateur qui donne des droits à d’autres clients
                            administrateurs et clients.
                        </Description>
                        <Description>
                            5.5 Spécificités liées aux abonnements physiques
                        </Description>
                        <Description>
                            5.5.1 Suspension de l’abonnement
                        </Description>
                        <Description>
                            5.5.1.1 Le client administrateur a la possibilité de suspendre temporairement la livraison
                            de la publication. La demande doit être effectuée par courriel à contact@mag-iris.com au
                            minimum trois jours avant le bouclage du numéro en cours.
                        </Description>
                        <Description>
                            5.5.2 Service aux utilisateurs abonnés
                        </Description>
                        <Description>
                            5.5.2 1 En cas de changement de domicile sur le territoire suisse, l’abonnement est
                            transféré à la nouvelle adresse, sans frais.
                        </Description>
                        <Description>
                            5.5.2.3 En cas de changement de domicile à l’étranger, dans le cadre d’un abonnement papier,
                            les tarifs de l’abonnement papier à l’étranger s’appliquent.
                        </Description>
                        <Description>
                            5.5.2.4 mag-iris décline toute responsabilité en cas de livraison perturbée à l’étranger.
                        </Description>
                        <Description>
                            5.6.1.2 Après paiement par carte bancaire, l’activation de l’abonnement est immédiate.
                        </Description>
                        <Description>
                            5.6.2 Accès électroniques
                        </Description>
                        <Description>
                            5.6.2.1 Toute indisponibilité temporaire des contenus électroniques ne donne lieu à aucun
                            dédommagement.
                        </Description>
                        <Description>
                            5.6.2.2 mag-iris n'assume aucune responsabilité en cas de problème d'accès lié à une
                            défectuosité des matériels et/ou logiciels du/de la client(e) ou à la connexion utilisée.
                            Le/la client(e) assume l'intégralité des coûts d'entretien, de réparation ou de correction
                            de ses propres matériels et logiciels.
                        </Description>
                        <Title>6. Paiement</Title>
                        <Description>
                            6.1 Le paiement d’un produit a lieu par carte de crédit.
                        </Description>
                        <Title>7. Adaptation des prix et des services</Title>
                        <Description>
                            7.1 mag-iris se réserve le droit d’adapter pour une nouvelle période d’abonnement les prix
                            des abonnements et l’étendue des services associés à l’abonnement.
                        </Description>
                        <Title>8. Commande et livraison</Title>
                        <Description>
                            8.1 Toutes les commandes sont fermes dès que l’utilisateur clique sur le bouton « payer »
                        </Description>
                        <Description>
                            8.2 Les frais d’expédition pour les livraisons en Suisse sont compris dans le prix. Pour les
                            livraisons à l’étranger, veuillez prendre contact avec notre service à la clientèle par
                            courriel à l’adresse contact@mag-iris.ch.
                        </Description>
                        <Title>9. Avis de non-responsabilité</Title>
                        <Description>
                            9.1 mag-iris ne répond que des dommages directs causés par une action intentionnelle ou une
                            faute grave de mag-iris elle-même. mag-iris décline expressément toute responsabilité pour
                            les dommages directs en raison d’une faute légère et pour les dommages indirects – quel que
                            soit le fondement juridique – sous réserve de dispositions légales impératives. Une
                            responsabilité extracontractuelle de mag-iris est exclue également – pour autant que la loi
                            l’autorise.
                        </Description>
                        <Description>
                            9.2 Il peut arriver de temps à autre que le site et/ou l’application ne soient pas
                            disponibles ou uniquement disponibles de manière limitée en raison de travaux d’entretien ou
                            pour d’autres raisons. mag-iris n’assume aucune responsabilité pour la non-disponibilité de
                            la plate-forme, la défaillance de certaines ou de toutes les fonctions ainsi que pour les
                            erreurs de fonctionnement des plate-formes mag-iris.com et mag-iris app
                        </Description>
                        <Description>
                            9.3 La plate-forme mag-iris.com contient des liens vers des sites web de tiers. Ces pages ne
                            sont ni exploitées ni contrôlées par mag-iris, qui n’assume aucune responsabilité pour le
                            contenu et l’exploitation des liens externes.
                        </Description>
                        <Title>10. Droits de propriété intellectuelle et autres droits</Title>
                        <Description>
                            10.1 mag-iris est propriétaire et exploitant de ses plate-formes. Tous les noms, marques,
                            logos, images, dessins, textes et autre matériel utilisés sur lesdites plates-formes
                            appartiennent à mag-iris. La reproduction (intégrale ou partielle), la transmission (par
                            voie électronique ou autre), la modification, la mise en rapport ou l’utilisation de ces
                            marques, noms, logos, images, dessins, textes et autre matériel à des fins publiques ou
                            commerciales sont interdites sans l’autorisation écrite préalable de mag-iris.
                        </Description>
                        <Title>11. Protection, traitement et sécurité des données</Title>
                        <Description>
                            11.1 Nous renvoyons à la Déclaration de protection des données faisant partie intégrante de
                            ces CGV en ce qui concerne la protection des données, les principes du traitement des
                            données et la sécurité des données.
                        </Description>
                        <Title>12. Dispositions générales</Title>
                        <Description>
                            12.1 mag-iris se réserve le droit de modifier ces CGV à tout moment. Les modifications de
                            ces CGV sont office d’acceptées par l’utilisateur si celui-ci ne fait pas opposition contre
                            ces nouvelles CGV dans un délai de 14 jours à partir de la publication, sur le site, des CGV
                            modifiées par courriel à l’adresse contact@mag-iris.com
                        </Description>
                        <Description>
                            12.2 Si certaines dispositions des CGV devaient être ou devenir invalides ou nulles dans
                            leur intégralité ou en partie, la validité et/ou la vigueur des autres dispositions des CGV
                            n’en sont pas affectées. Les dispositions invalides et/ou nulles seront remplacées par des
                            dispositions juridiquement valides dont l’intention et l’objectif économique se rapprochent
                            le plus des dispositions invalides et/ou nulles. Il en va de même en cas d’éventuelles
                            lacunes.
                        </Description>
                        <Title>13. For et droit applicable </Title>
                        <Description>
                            13.1 Ces CGV sont régies par le droit suisse.
                        </Description>
                        <Description>
                            13.2 Les seuls tribunaux compétents pour tous les litiges en rapport avec ces CGV, y compris
                            ceux concernant la conclusion valide, la validité légale, l’interprétation, l’exécution, la
                            violation, la modification ou la dissolution du rapport contractuel entre l’utilisateur et
                            mag-iris ainsi que les prétentions extracontractuelles en rapport avec ces litiges sont les
                            tribunaux de Genève.
                        </Description>
                    </ContentWithPaddingXl>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        );
    }
}

export default withTranslation()(CGV);
