import React from "react";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";
import EmailIllustrationSrc from "../images/email-illustration.svg";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase} from "../components/misc/Headings";
import {PrimaryButton as PrimaryButtonBase} from "../components/misc/Buttons";
import Header from "./common/Header";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Constants from '../constants';
import {getLanguage} from "./common/languages";
import * as axios from "axios";
import Footer from "./common/Footer";
import GreenPoint from "./common/GreenPoint";
import SocialNetworks from "./common/SocialNetworks";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between lg:px-20 max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw.h1`font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-pastel leading-tight italic`;
const Description = tw.p`my-5 lg:my-8 text-base text-black xl:text-lg font-light`;
const Error = tw.p`my-5 lg:my-8 text-base text-red-600 xl:text-lg`;
const Success = tw.p`my-5 lg:my-8 text-base text-green-classic xl:text-lg`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-purple-classic`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-40`}
`
const CaptchaWrapper = tw.div`mt-5`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            verifToken: "",
            lang: getLanguage(),
            error: "",
            success: "",
            curentlySend: false,
            captchaKey: 1
        }
    }

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const {t} = this.props;

        if (this.state.verifToken === "") {
            this.setState({error: t("CONTACT_ERROR_CAPTCHA")})
            return;
        }

        this.setState({
            error: "",
            curentlySend: true,
            success: ""
        });

        const values = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            subject: this.state.subject,
            message: this.state.message,
            captchaResponse: this.state.verifToken,
        };

        const options = {
            url: Constants.baseUrl + Constants.contactRoute,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: values
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    let oldKey = this.state.captchaKey;
                    oldKey++;
                    this.setState({
                        success: t("CONTACT_SUCCESS"),
                        error: "",
                        curentlySend: false,
                        name: "",
                        email: "",
                        phone: "",
                        subject: "",
                        message: "",
                        verifToken: "",
                        captchaKey: oldKey
                    });
                }
            })
            .catch(error => {
                this.setState({
                    error: t("CONTACT_ERROR_SERVER"),
                    curentlySend: false
                });
            });

        console.log(this.state);
    }

    handleVerificationSuccess = (token) => {
        this.setState({verifToken: token});
        console.log("handleVerificationSuccess", token);
    }

    buttonStyle = () => {
        if (this.state.disabled) {

        }
    }


    render() {

        const {t} = this.props;

        return (
            <AnimationRevealPage>
                <GreenPoint animation={false}/>
                <SocialNetworks showScrollButton={false} animation={false}/>
                <Header roundedHeaderButton={true} animation={false}/>
                <Container animation={false}>
                    <TwoColumn>
                        <ImageColumn>
                            <Image imageSrc={EmailIllustrationSrc}/>
                        </ImageColumn>
                        <TextColumn textOnLeft={true}>
                            <TextContent>
                                <Heading>{t("CONTACT_TITLE")}</Heading>
                                <Description>{t("CONTACT_DESCRIPTION")}</Description>
                                <Error>{this.state.error}</Error>
                                <Success>{this.state.success}</Success>
                                <Form onSubmit={this.handleSubmit}>
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder={t("CONTACT_NAME_PLACEHOLDER")}
                                        onChange={(evt) => this.handleChange(evt, 'name')}
                                        value={this.state.name}
                                        required
                                    />
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder={t("CONTACT_EMAIL_PLACEHOLDER")}
                                        onChange={(evt) => this.handleChange(evt, 'email')}
                                        value={this.state.email}
                                        required
                                    />
                                    <Input
                                        type="tel"
                                        name="phone"
                                        placeholder={t("CONTACT_PHONE_PLACEHOLDER")}
                                        onChange={(evt) => this.handleChange(evt, 'phone')}
                                        value={this.state.phone}
                                        required
                                    />
                                    <Input
                                        type="text"
                                        name="subject"
                                        placeholder={t("CONTACT_SUBJECT_PLACEHOLDER")}
                                        onChange={(evt) => this.handleChange(evt, 'subject')}
                                        value={this.state.subject}
                                        required
                                    />
                                    <Textarea
                                        name="message"
                                        placeholder={t("CONTACT_MESSAGE_PLACEHOLDER")}
                                        onChange={(evt) => this.handleChange(evt, 'message')}
                                        value={this.state.message}
                                        required
                                    />
                                    <CaptchaWrapper>
                                        <HCaptcha
                                            key={this.state.captchaKey}
                                            languageOverride={this.state.lang}
                                            sitekey={Constants.hcaptchaKey}
                                            onVerify={token => this.handleVerificationSuccess(token)}
                                        />
                                    </CaptchaWrapper>

                                    <SubmitButton style={this.buttonStyle}>
                                        {
                                            this.state.curentlySend ? (
                                                <div className="lds-ripple">
                                                    <div/>
                                                    <div/>
                                                </div>
                                            ) : t("CONTACT_SUBMIT_LABEL")
                                        }
                                    </SubmitButton>
                                </Form>
                            </TextContent>
                        </TextColumn>
                    </TwoColumn>
                </Container>
                <Footer animation={false}/>
            </AnimationRevealPage>
        )
    }
}

export default withTranslation()(Contact)
