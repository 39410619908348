import React from "react";
import {withTranslation} from "react-i18next";
import GreenPoint from "./common/GreenPoint";
import SocialNetworks from "./common/SocialNetworks";
import Header from "./common/Header";
import Footer from "./common/Footer";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import tw from "twin.macro";
import {PrimaryButton as PrimaryButtonBase} from "../components/misc/Buttons";
import Constants from "../constants";
import * as axios from "axios";
import {getLanguage} from "./common/languages";

const Container = tw.div`mx-8 my-20 lg:mx-80 lg:mb-80 lg:mt-32 text-center`;
const Heading = tw.h1`font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-pastel leading-tight italic`;
const Paragraph = tw.p`my-5 lg:my-8 text-base text-black xl:text-lg font-light`;
const Input = tw.input`mt-6 first:ml-0 sm:ml-5 w-full border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-purple-classic`
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col mx-auto md:mx-0 w-full`
const FormLine = tw.div`sm:flex w-full`;

const Error = tw.p`my-5 lg:my-8 text-base text-red-600 xl:text-lg`;
const Success = tw.p`my-5 lg:my-8 text-base text-green-classic xl:text-lg`;
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

class ComingSoon extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: "",
            curentlySend: false,
            success: "",
            firstname: "",
            lastname: "",
            email: ""
        }
    }

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const {t} = this.props;

        this.setState({
            error: "",
            curentlySend: true,
            success: ""
        });

        const values = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            lang: getLanguage().toUpperCase(),
            list: "NEWS"
        };

        const options = {
            url: Constants.baseUrl + Constants.newsletterRoute,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: values
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        success: t("COMING_SOON_SUCCESS"),
                        error: "",
                        curentlySend: false,
                        firstname: "",
                        lastname: "",
                        email: ""
                    });
                }
            })
            .catch(error => {
                this.setState({
                    error: t("COMING_SOON_ERROR"),
                    curentlySend: false
                });
            });

    }

    render() {

        const {t} = this.props;

        return (
            <AnimationRevealPage>
                <GreenPoint/>
                <SocialNetworks showScrollButton={false}/>
                <Header roundedHeaderButton={true}/>
                <Container>
                    <Heading>{t("COMING_SOON_TITLE")}</Heading>
                    <Paragraph>{t("COMING_SOON_TEXT")}</Paragraph>
                    <Error>{this.state.error}</Error>
                    <Success>{this.state.success}</Success>
                    <Form onSubmit={this.handleSubmit}>
                        <FormLine>
                            <Input
                                type="text"
                                name="firstname"
                                placeholder={t("COMING_SOON_FIRSTNAME_PLACEHOLDER")}
                                onChange={(evt) => this.handleChange(evt, 'firstname')}
                                value={this.state.firstname}
                            />
                            <Input
                                type="text"
                                name="lastname"
                                placeholder={t("COMING_SOON_LASTNAME_PLACEHOLDER")}
                                onChange={(evt) => this.handleChange(evt, 'lastname')}
                                value={this.state.lastname}
                            />
                        </FormLine>
                        <FormLine>
                            <Input
                                type="email"
                                name="name"
                                placeholder={t("COMING_SOON_EMAIL_PLACEHOLDER")}
                                onChange={(evt) => this.handleChange(evt, 'email')}
                                value={this.state.email}
                                required
                            />
                        </FormLine>
                        <SubmitButton>
                            {
                                this.state.curentlySend ? (
                                    <div className="lds-ripple">
                                        <div/>
                                        <div/>
                                    </div>
                                ) : t("COMING_SOON_SUBMIT")
                            }
                        </SubmitButton>
                    </Form>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        )
    }
}

export default withTranslation()(ComingSoon);
