import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import "../node_modules/react-modal-video/css/modal-video.min.css";
import React, {Suspense} from "react";
import {css} from "styled-components/macro"; //eslint-disable-line

import i18n from "./i18n.js";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Index from "./pages/Index";
import Blog from "./pages/Blog";
import {I18nextProvider} from "react-i18next";
import Contact from "./pages/Contact";
import BlogOne from "./pages/BlogOne";
import ComingSoon from "./pages/ComingSoon";
import * as Klaro from "klaro/dist/klaro-no-css";
import "styles/klaro.css";
import {getLanguage} from "./pages/common/languages";
import CGV from "./pages/CGV";
import CGU from "./pages/CGU";
import Confidentialite from "./pages/Confidentialite";

export default function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <Suspense fallback="loading">
                <Router>
                    <Switch>
                        <Route path="/comingsoon">
                            <ComingSoon/>
                        </Route>
                        <Route path="/contact">
                            <Contact/>
                        </Route>
                        <Route path="/blog/:id" component={BlogOne}/>
                        <Route path="/blog">
                            <Blog/>
                        </Route>
                        <Route path="/cgv">
                            <CGV/>
                        </Route>
                        <Route path="/cgu">
                            <CGU/>
                        </Route>
                        <Route path="/confidentialite">
                            <Confidentialite/>
                        </Route>
                        <Route path="/">
                            <Index/>
                        </Route>
                    </Switch>
                </Router>
            </Suspense>
        </I18nextProvider>
    );
}

if (process.env.REACT_APP_ENV === 'production') {
    const initKlaro = () => {
        const config = {
            cookieName: 'klaro-rgpd-2',
            lang: getLanguage(),
            apps: [
                {
                    name: "googleAnalytics",
                    purposes: ["analytics"],
                    default: true,
                },
                {
                    name: "facebookPixel",
                    purposes: ["analytics"],
                    default: true,
                },
                {
                    name: "snapPixel",
                    purposes: ["analytics"],
                    default: true,
                }
            ],
        };

        window.klaro = Klaro;
        window.klaroConfig = config;

        Klaro.setup(config);
    }

    // initKlaro();

    // i18next.on('languageChanged', initKlaro);
}
