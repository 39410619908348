import React from "react";
import {withTranslation} from "react-i18next";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Header from "./common/Header";
import {Container, ContentWithPaddingXl} from "../components/misc/Layouts";
import Footer from "./common/Footer";
import tw from "twin.macro";
import * as axios from "axios";
import Constants from "../constants";
import styled from "styled-components";
import {css} from "styled-components/macro";
import {getLanguage} from "./common/languages";
import {PrimaryButton as PrimaryButtonBase} from "../components/misc/Buttons";
import SocialNetworks from "./common/SocialNetworks";
import GreenPoint from "./common/GreenPoint";
import {getImageUrl} from "../utils";

const HeadingRow = tw.div`flex block`;
const Heading = tw.h1`font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-pastel leading-tight`;
const Loader = tw.div`w-1 mx-auto mt-8`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center`}
`;
const HDate = tw.h2`mt-2`;

const Post = tw.div`mt-5`;
const Description = styled.div`
  ${tw`pt-8 font-light`}
  ${props =>
          css`
            h2 {
              ${tw`text-purple-classic text-3xl`}
            }

            h3 {
              ${tw`text-purple-classic text-2xl`}
            }

            h4 {
              //chapeau
              ${tw`text-xl mb-10`}
            }

            a {
              ${tw`text-purple-classic hover:text-purple-pastel`}
            }

            strong {
              ${tw`font-medium`}
            }

            p:first-of-type:first-letter {
              ${tw`text-purple-classic text-5xl pr-1 float-left uppercase`}
            }

            blockquote {
              ${tw`italic pl-3 border-l-2 text-gray-800`}
            }

            blockquote p:first-of-type:first-letter {
              all: unset;
            }

          `}
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-24 md:mt-8 text-sm inline-block mx-auto md:mx-0 font-medium`
]);

class BlogOne extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            articleId: this.props.match.params.id,
            article: null,
            loading: true
        }

    }

    componentDidMount() {
        this.loadArticle();
    }

    loadArticle() {
        const dateOption = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        axios.get(Constants.baseCmsUrl + Constants.articlesRoute + "/" + this.state.articleId)
            .then((response) => {
                const article = response.data;

                article.date = new Date(article.publish_date).toLocaleDateString(getLanguage().toUpperCase(), dateOption);

                this.setState({
                    article: article,
                    loading: false
                })

            })
            .catch(err => this.setState({loading: false}));
    }

    goBack = () => {
        this.props.history.push("/blog");
    }

    render() {
        const {t} = this.props;

        let imageSrc = null;

        if (this.state.article && this.state.article.images.length > 0) {
            imageSrc = getImageUrl(this.state.article.images[0], "large");
        }

        return (
            <AnimationRevealPage>
                <GreenPoint/>
                <SocialNetworks showScrollButton={false}/>
                <Header/>
                <Container>
                    <ContentWithPaddingXl>
                        {
                            this.state.loading ? (
                                <Loader>
                                    <div className="lds-ripple purple">
                                        <div/>
                                        <div/>
                                    </div>
                                </Loader>
                            ) : this.state.article === null ? (
                                <div>
                                    <HeadingRow>
                                        <Heading>{t("BLOG_NOT_FOUND")}</Heading>
                                    </HeadingRow>
                                </div>
                            ) : (
                                <div>
                                    <HeadingRow>
                                        <Heading>{this.state.article.title}</Heading>
                                        <HDate>{this.state.article.date}</HDate>
                                    </HeadingRow>
                                    <Post>
                                        {imageSrc && <Image imageSrc={imageSrc}/>}
                                        <Description dangerouslySetInnerHTML={{__html: this.state.article.content}}/>
                                    </Post>
                                </div>
                            )
                        }
                        <PrimaryButton onClick={this.goBack}>{t("BLOG_GO_BACK")}</PrimaryButton>
                    </ContentWithPaddingXl>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        )
    }
}

export default withTranslation()(BlogOne);
