import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/IRIS_LOGO_NOIR.png";
import {ReactComponent as FacebookIcon} from "images/facebook-icon.svg";

import instaIcon from "images/insta.png";
import linkedinIcon from "images/linkedin.png";
import Constants from "../../constants";
import {PrimaryButton as PrimaryButtonBase} from "../../../src/components/misc/Buttons";
import {useTranslation, withTranslation} from "react-i18next";
import {getLanguage} from "./languages";
import * as axios from "axios";

const Container = tw.div`relative bg-purple-pastel text-black -mb-8 -mx-8 px-8 py-20 lg:py-16 z-30`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;

const Text = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-light text-black`;
const TextCopyright = tw.p`text-center text-xs mt-8 md:mt-0 font-light text-black`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full text-black hover:bg-purple-classic transition duration-300 mr-4 last:mr-0 border-solid border border-black`}
  svg {
    ${tw`w-4 h-4`}
  }

  img {
    ${tw`w-4 h-4`}
  }
`;

const SubscribeNewsletterContainer = tw.div`mx-auto lg:mx-0 mb-8 px-5`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-white px-6 py-3 focus:outline-none w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto px-8 py-3`;
const Error = tw.div`text-center text-xl mt-3 text-red-800`;
const Success = tw.div`text-center text-xl mt-3 text-black`;

class Footer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: "",
            curentlySend: false,
            success: "",
            email: ""
        }
    }

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const {t} = this.props;

        this.setState({
            error: "",
            curentlySend: true,
            success: ""
        });

        const values = {
            email: this.state.email,
            lang: getLanguage().toUpperCase(),
            list: "NEWS"
        };

        const options = {
            url: Constants.baseUrl + Constants.newsletterRoute,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: values
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        success: t("FOOTER_EMAIL_SUCCESS"),
                        error: "",
                        curentlySend: false,
                        email: ""
                    });
                }
            })
            .catch(error => {
                this.setState({
                    error: t("FOOTER_EMAIL_ERROR"),
                    curentlySend: false
                });
            });
    }

    render() {

        const {t} = this.props;

        return (
            <Container>
                <Content>
                    <ThreeColRow>
                        <LogoContainer>
                            <LogoImg src={LogoImage}/>
                        </LogoContainer>
                        <div>
                            <SubscribeNewsletterContainer>
                                <Text>
                                    {t("FOOTER_NEWSLETTER_TITLE")}
                                </Text>
                                <Error>{this.state.error}</Error>
                                <Success>{this.state.success}</Success>
                                <SubscribeForm onSubmit={this.handleSubmit}>
                                    <Input
                                        type="email"
                                        placeholder={t("FOOTER_EMAIL_PLACEHOLDER")}
                                        value={this.state.email}
                                        onChange={(evt) => this.handleChange(evt, 'email')}
                                        required
                                    />
                                    <SubscribeButton type="submit">
                                        {
                                            this.state.curentlySend ? (
                                                <div className="lds-ripple purple">
                                                    <div/>
                                                    <div/>
                                                </div>
                                            ) : (
                                                t("FOOTER_EMAIL_SUBMIT")
                                            )
                                        }
                                    </SubscribeButton>
                                </SubscribeForm>
                            </SubscribeNewsletterContainer>
                            <TextCopyright>{t("FOOTER_COPYRIGHT").replace("%YEAR%", new Date().getFullYear())}
                                | <a href={"/confidentialite"}>{t("FOOTER_PRIVACY_POLICY")} </a>
                                | <a href={"/cgv"}>CGV </a>
                                | <a href={"/cgu"}>CGU </a>
                            </TextCopyright>
                            <TextCopyright>
                                <br/>
                                {t("FOOTER_DEVELOPPED_BY")}
                                <a href={"https://wm-dev.fr/"} target={"_blank"}>WM Dev</a>
                            </TextCopyright>
                        </div>
                        <SocialLinksContainer>
                            <SocialLink href={Constants.social.facebook} target="_blank">
                                <FacebookIcon/>
                            </SocialLink>
                            <SocialLink href={Constants.social.linkedin} target="_blank">
                                <img src={linkedinIcon} alt="Linkedin"/>
                            </SocialLink>
                            <SocialLink href={Constants.social.instagram} target="_blank">
                                <img src={instaIcon} alt="Instagram"/>
                            </SocialLink>
                        </SocialLinksContainer>
                    </ThreeColRow>
                </Content>
            </Container>
        )
    }
}

export default withTranslation()(Footer);
