import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Link} from "react-scroll";
import Constants from "../../constants";
import facebookIcon from "../../images/fb-purple.png";
import linkedinIcon from "../../images/linkdin-purple.png";
import instaIcon from "../../images/insta-purple.png";
import {withTranslation} from "react-i18next";

const FixedElements = tw.div`fixed w-0 lg:w-20 h-full z-20 tracking-widest`;

const FixedElementsLeft = styled(FixedElements)`
  ${tw`left-0`}
`;

const ScrollToContainer = tw.div``;

const VerticalContainer = tw.div`absolute invisible lg:visible text-purple-classic uppercase`;

const VerticalContainerBottomLeft = styled(VerticalContainer)`
  ${tw`bottom-0 left-0 ml-5 pb-12`}
`;

const verticalStyle = {
    writingMode: "vertical-rl",
    transform: "rotate(180deg)"
}

const RSContainer = tw.div`absolute invisible lg:visible text-purple-classic uppercase top-1/2 left-0 pl-6 mt--40`;

const SocialContainer = styled.div`p-5`;

const SocialLink = styled.a`
  ${tw`cursor-pointer pt-2`}
  img {
    ${tw`w-8 h-8 mt-2`}
  }
`;


class SocialNetworks extends React.Component {


    render() {
        const {t} = this.props;

        return (
            <FixedElementsLeft>
                {
                    this.props.showScrollButton && this.props.isOnTop ? (
                        <ScrollToContainer>
                            <VerticalContainerBottomLeft>
                                <div style={verticalStyle}>
                                    <Link to={this.props.scrollTo} spy={true} smooth={true} offset={50}
                                          duration={500}>
                                        ← {t("HOME_SIDE_WAY")}
                                    </Link>
                                </div>
                            </VerticalContainerBottomLeft>
                        </ScrollToContainer>
                    ) : (
                        <RSContainer>
                            <div style={{...verticalStyle, marginBottom: "1rem"}}>
                                {t("HOME_SIDE_SN")}
                            </div>
                            <SocialContainer>
                                <SocialLink href={Constants.social.facebook} target="_blank">
                                    <img src={facebookIcon} alt="Facebook"/>
                                </SocialLink>
                                <SocialLink href={Constants.social.linkedin} target="_blank">
                                    <img src={linkedinIcon} alt="Linkedin"/>
                                </SocialLink>
                                <SocialLink href={Constants.social.instagram} target="_blank">
                                    <img src={instaIcon} alt="Instagram"/>
                                </SocialLink>
                            </SocialContainer>
                        </RSContainer>
                    )
                }
            </FixedElementsLeft>
        )
    }

}

export default withTranslation()(SocialNetworks);
