import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "./common/Header";
import Footer from "./common/Footer";
import {withTranslation} from "react-i18next";
import GreenPoint from "./common/GreenPoint";
import SocialNetworks from "./common/SocialNetworks";

const Title = tw.div`mt-1 font-black text-2xl text-black group-hover:text-purple-classic transition duration-300 mt-3`;
const Description = tw.p``;

class Confidentialite extends React.Component {

    render() {

        return (
            <AnimationRevealPage>
                <GreenPoint/>
                <SocialNetworks showScrollButton={false}/>
                <Header/>
                <Container>
                    <ContentWithPaddingXl>
                        <Description>
                            Chère cliente, cher client,<br/><br/>
                            Nous vous invitons à vous familiariser avec la présente politique de confidentialité, en
                            particulier quant à la nature des données à caractère personnel collectées par Mag-iris, à
                            la manière dont ces données à caractère personnel sont traitées et aux personnes à qui elles
                            peuvent être transmises.<br/><br/>
                            En utilisant un site Internet ou une application mobile de Mag-iris, vous déclarez accepter
                            le traitement de vos données à caractère personnel selon les dispositions de cette politique
                            de confidentialité.<br/><br/>
                            Les données à caractère personnel sont des indications qui se rapportent à votre personne et
                            donnent des informations sur vous (par exemple, prénom et nom, adresse postale, adresse
                            courriel, date de naissance, numéro de téléphone).<br/><br/>
                            Le traitement englobe chaque manipulation de vos données à caractère personnel, qui englobe
                            la saisie, la collecte, l’anonymisation, l’enregistrement, la gestion, l’utilisation, la
                            transmission, la communication ou la suppression de vos données à caractère
                            personnel.<br/><br/>
                            Nous respectons les dispositions impératives des lois suisses sur la protection des données
                            et, le cas échéant, celles de l’Union Européenne. Dès lors, nous vous informons en toute
                            transparence sur les données à caractère personnel que nous traitons et à quelles fins. Nous
                            traitons uniquement les données à caractère personnel que nous recevons
                            légalement.<br/><br/>
                            Vos données à caractère personnel vous appartiennent. Vous pouvez dès lors choisir la
                            manière dont vos données à caractère personnel doivent être traitées et vous pouvez à tout
                            moment savoir ce qu’il advient de vos données à caractère personnel.<br/><br/>
                        </Description>
                        <Description>
                            Conformément à la loi fédérale sur la protection des données, vous disposez des droits
                            suivants :
                        </Description>
                        <Title>
                            1. Droit d’accès
                        </Title>
                        <Description>
                            Selon la loi sur la protection des données, vous avez le droit de savoir gratuitement si et
                            quelles données à caractère personnel mag-iris traite à votre égard. Vous devez nous
                            adresser par écrit votre demande de renseignements avec une copie de votre pièce d’identité.
                        </Description>
                        <Title>
                            2. Droit de rectification de vos données à caractère personnel
                        </Title>
                        <Description>
                            Vous avez à tout moment le droit de rectifier vos données à caractère personnel si des
                            données erronées à votre égard sont traitées.
                        </Description>
                        <Title>
                            3. Droit de suppression de vos données à caractère personnel
                        </Title>
                        <Description>
                            Lorsque le traitement de vos données à caractère personnel n’est plus nécessaire,
                            typiquement parce qu’il n’existe plus de relation contractuelle avec mag-iris, ou si vous
                            indiquez expressément votre désaccord avec le traitement de vos données à caractère
                            personnel, vous avez le droit d’exiger la suppression de vos données. Nous supprimerons
                            aussitôt vos données à moins que nous ayons l’obligation de conserver vos données à
                            caractère personnel, conformément notamment à l’obligation légale de conservation.
                        </Description>
                        <Title>
                            4. Droit à la limitation du traitement de vos données à caractère personnel
                        </Title>
                        <Description>
                            De même, vous pouvez solliciter une limitation du traitement de vos données à caractère
                            personnel à moins que nous ayons l’obligation de conserver vos données à caractère
                            personnel, conformément notamment à l’obligation légale de conservation.
                        </Description>
                        <Title>
                            5. Droit d’opposition
                        </Title>
                        <Description>
                            Vous êtes en droit de révoquer votre consentement ou d’exprimer votre refus à tout moment
                            quant à l’autorisation de traiter vos données à caractère personnel. De même, si le
                            traitement de vos données à caractère personnel est utile à des fins de marketing ou
                            publicitaires, vous pouvez révoquer à tout moment votre consentement quant au traitement à
                            ces fins de manière générale ou concernant certains produits ou services.<br/><br/>
                            En outre, à tout moment vous former opposition à l’envoi de publicité et au traitement de
                            vos données à caractère personnel à des fins de marketing et de publicité.
                        </Description>
                        <Title>
                            6. Droit d’introduire une réclamation auprès d’une autorité de contrôle
                        </Title>
                        <Description>
                            Vous avez le droit d’introduire une réclamation auprès de l‘autorité de contrôle
                            responsable, si vous êtes d’avis qu’un traitement de vos données à caractère personnel n’est
                            pas conforme aux principes de la protection des données.
                        </Description>
                        <Title>
                            7. Base juridique
                        </Title>
                        <Description>
                            Pour le traitement de vos données à caractère personnel, nous nous conformons à la loi
                            suisse sur la protection des données et son règlement d’application, ainsi qu’au règlement
                            général sur la protection des données de l’Union Européenne, dans la mesure où il trouve
                            application.
                        </Description>
                    </ContentWithPaddingXl>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        );
    }
}

export default withTranslation()(Confidentialite);
