import React from "react";
import {motion} from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/IRIS_LOGO_violet.png";
import {ReactComponent as MenuIcon} from "feather-icons/dist/icons/menu.svg";
import {ReactComponent as CloseIcon} from "feather-icons/dist/icons/x.svg";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {languages, getLanguage} from "./languages";
import {ReactComponent as ChevronDownIcon} from "feather-icons/dist/icons/chevron-down.svg";
import Constants from "../../constants";

const Header = tw.header`
  flex justify-between mx-auto lg:mr-16 lg:ml-40
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-xl my-2 lg:text-sm lg:mx-6 lg:my-0
  font-medium tracking-wide transition duration-300 text-black
  pb-1 border-b-2 border-transparent hover:border-black lowercase
`;

export const NavLinkLang = tw.a`
  text-xl my-2 lg:text-sm lg:mx-6 lg:my-0
  tracking-wide transition duration-300 text-black
  pb-1 border-b-2 border-transparent hover:border-black
  cursor-pointer inline-block font-light
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-pastel text-black
  hocus:bg-green-classic hocus:text-black focus:shadow-outline
  text-center
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-32`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export const DropDownContent = tw.div`
  z-20 bg-white py-3 group-hover:block hidden lg:absolute lg:min-w-full
`;

export const LangOption = tw.a`
  text-xl my-2 lg:text-base lg:mx-6 lg:my-0
  tracking-wide transition duration-300 text-black
  pb-1 border-b-2 border-transparent hover:border-black
  block py-3 cursor-pointer
`;

export const DropDownWrapper = tw.div`
    inline-block relative mt-2 lg:mt-0
`;


export default ({roundedHeaderButton = true, logoLink, links, className, collapseBreakpointClass = "lg"}) => {

    const changeLanguage = (lang) => {
        i18next.changeLanguage(lang);
    }

    const {t, i18n} = useTranslation();

    const defaultLinks = [
        <NavLinks key={1}>
            <NavLink href="/">{t("MENU_HOME")}</NavLink>
            <NavLink href="/blog">{t("MENU_BLOG")}</NavLink>
            <NavLink href="/contact">{t("MENU_CONTACT")}</NavLink>
            <NavLink href={Constants.privateAppUrl + "/login"}>{t("MENU_LOGIN")}</NavLink>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}
                         href={Constants.privateAppUrl + "/signup"}>{t("MENU_SIGNUP")}</PrimaryLink>
            <DropDownWrapper className="dropdown group">
                <NavLinkLang className="dropbtn">
                    {languages[getLanguage()]}
                    <ChevronDownIcon style={{display: "inline"}}/>
                </NavLinkLang>
                <DropDownContent className="dropdown-content">
                    {
                        Object.entries(languages).map(val => {
                            if (val[0] !== getLanguage()) {
                                return (
                                    <LangOption key={val[0]}
                                                onClick={() => changeLanguage(val[0])}>{val[1]}</LangOption>
                                )
                            }
                        })
                    }
                </DropDownContent>
            </DropDownWrapper>
        </NavLinks>
    ];

    const {showNavLinks, animation, toggleNavbar} = useAnimatedNavToggler();
    const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

    const defaultLogoLink = (
        <LogoLink href="/">
            <img src={logo} alt="logo"/>
        </LogoLink>
    );

    logoLink = logoLink || defaultLogoLink;
    links = links || defaultLinks;

    return (
        <Header className={className || "header-light"}>
            <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
                {logoLink}
                {links}
            </DesktopNavLinks>

            <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
                {logoLink}
                <MobileNavLinks initial={{x: "150%", display: "none"}} animate={animation}
                                css={collapseBreakpointCss.mobileNavLinks}>
                    {links}
                </MobileNavLinks>
                <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
                    {showNavLinks ? <CloseIcon tw="w-6 h-6"/> : <MenuIcon tw="w-6 h-6"/>}
                </NavToggle>
            </MobileNavLinksContainer>
        </Header>
    );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
    sm: {
        mobileNavLinks: tw`sm:hidden`,
        desktopNavLinks: tw`sm:flex`,
        mobileNavLinksContainer: tw`sm:hidden`
    },
    md: {
        mobileNavLinks: tw`md:hidden`,
        desktopNavLinks: tw`md:flex`,
        mobileNavLinksContainer: tw`md:hidden`
    },
    lg: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`
    },
    xl: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`
    }
};
