import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import translationsFR from "translations/fr.json";
import translationsDE from "translations/de.json";
import translationsIT from "translations/it.json";

i18n
    .use(detector)
    .init({
        resources: {
            fr: {
                translation: translationsFR
            },
            de: {
                translation: translationsDE
            },
            it: {
                translation: translationsIT
            }
        },
        fallbackLng: "fr",

        interpolation: {
            escapeValue: false
        }
    });


export default i18n;
