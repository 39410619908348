import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "./common/Header";
import Footer from "./common/Footer";
import {withTranslation} from "react-i18next";
import GreenPoint from "./common/GreenPoint";
import SocialNetworks from "./common/SocialNetworks";

const HeadingRow = tw.div`flex`;
const Heading = tw.h1`font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-pastel leading-tight mt-5`;

const Title = tw.div`mt-1 font-black text-2xl text-black group-hover:text-purple-classic transition duration-300`;
const Description = tw.p``;

class CGU extends React.Component {

    render() {

        return (
            <AnimationRevealPage>
                <GreenPoint/>
                <SocialNetworks showScrollButton={false}/>
                <Header/>
                <Container>
                    <ContentWithPaddingXl>
                        <HeadingRow>
                            <Heading>Conditions générales d'utilisation</Heading>
                        </HeadingRow>
                        <Title>1. Objet</Title>
                        <Description>
                            Les présentes conditions générales d’utilisation régissent l’utilisation du site
                            www.mag-iris.com ainsi que l’application Mag-Iris App.<br/>
                            L’éditeur de ce site est mag-iris, chemin Paul-Rose 10 – 1213 Onex, Suisse<br/>
                            Les présentes conditions générales s’appliquent à l’ensemble du site et de l’application,
                            pour tous les produits et services qui y sont proposés et sont susceptibles d’être
                            complétées par des conditions générales particulières propres à certains services. Dans un
                            tel cas, les conditions générales particulières se trouveront directement sur la page du
                            contrat ou du mandat en lien avec les services spécifiques. Si les conditions générales
                            particulières devaient être en contradiction avec les présentes CGU, les conditions
                            générales particulières priment.<br/>
                        </Description>
                        <br/>
                        <Title>2. Définitions</Title>
                        <Description>
                            Site: le site internet www.mag-iris.com<br/>
                            Application : l’application Mag-Iris App<br/>
                            Contenu: toute information, donnée, texte, logiciel, musique, photographie, graphique,
                            image, vidéo, message ou toute donnée se trouvant sur le site et/ou sur l’application <br/>
                            Editeur: l’éditeur du site et de l’application <br/>
                            Conditions Générales d’Utilisation ou CGU: les présentes conditions générales, applicables
                            pour l’utilisation du site et de l’application. <br/>
                            Utilisateur: utilisateur du site et de l’application. <br/>
                            Service: tous les services du site et de l’application.
                        </Description>
                        <br/>
                        <Title>3. Acceptation des CGU</Title>
                        <Description>
                            L’utilisateur consent à être lié par les CGU dans leur intégralité lors de l’utilisation du
                            site et de l’application.
                        </Description>
                        <br/>
                        <Title>4. Modification des CGU</Title>
                        <Description>
                            Les CGU peuvent être modifiées en tout temps par l’éditeur, à son entière discrétion,
                            sans devoir d’annonce particulier. Il incombe à l’utilisateur de vérifier la nouvelle teneur
                            de celles-ci à chaque fois qu’il se rend sur le site et/ou sur l’application.
                        </Description>
                        <br/>
                        <Title>5. Utilisation du site et de l’application</Title>
                        <Description>
                            Le site et l’application constituent des plateformes comportant différentes contributions
                            rédactionnelles de la part de l’éditeur du site.<br/>
                            L’utilisateur s’engage à n’employer le contenu qu’à des fins licites, dans le respect du
                            droit suisse en vigueur ou de tout droit qui s’appliquerait à lui, ainsi qu’en accord avec
                            les CGU. L’utilisateur sera tenu pour responsable en cas de violation.
                        </Description>
                        <br/>
                        <Title>6. Contenu</Title>
                        <Description>
                            Malgré le soin apporté au travail de commercialisation effectué, l’éditeur ne peut garantir
                            l’exactitude de l’ensemble des informations qui y figurent.<br/>
                            Par conséquent, sous réserve de disposition découlant de dispositions légales impératives de
                            droit suisse, l’éditeur ne peut être tenu responsable de tout dommage, direct ou indirect,
                            de quelque nature que ce soit, résultant de la consultation et/ou de l’utilisation du site
                            et/ou de l’application, en particulier de toute erreur ou inexactitude qu’il pourrait
                            contenir.
                        </Description>
                        <br/>
                        <Title>7. Propriété intellectuelle</Title>
                        <Description>
                            L’éditeur est le propriétaire exclusif de tous les droits de propriété intellectuelle tant
                            sur la structure du site et de l’application que sur leur contenu.<br/>
                            Tout contenu reproduit sur le site et/ou l’application est protégé par des droits de
                            propriété intellectuelle et est la propriété exclusive de l’éditeur ou de ses
                            partenaires.<br/>
                            Par conséquent, toute reproduction, représentation, adaptation, modification, traduction,
                            transformation, diffusion, intégration dans un autre site (framing), exploitation
                            commerciale et/ou réutilisation de quelque manière que ce soit du contenu est strictement
                            interdite sans l’autorisation écrite préalable de l’éditeur.<br/>
                            La consultation et l’utilisation du site et/ou de l’application ne confère aucun droit de
                            propriété intellectuelle à l’utilisateur sur un quelconque élément publié sur ces
                            plateformes.<br/>
                            Tout utilisation contraire au droit ou aux CGU pourra donner lieu à des poursuites
                            judiciaires civiles et/ou pénales et au paiement de dommages-intérêts.<br/>
                        </Description>
                        <br/>
                        <Title>8. Liens hypertextes</Title>
                        <Description>
                            Le site peut contenir des liens hypertextes renvoyant vers d’autres sites web. L’utilisateur
                            visite ces sites sous sa seule et entière responsabilité.<br/>
                            Le site ne peut en aucun cas être tenu pour responsable du contenu de ces sites tiers, ni du
                            dommage subi du fait de leur utilisation.<br/>
                        </Description>
                        <br/>
                        <Title>9. Protection des données</Title>
                        <Description>
                            L’éditeur s’engage à protéger les données des utilisateurs. Le site et ses partenaires
                            prennent toutes les précautions face au vol de données ainsi qu’aux intrusions malveillantes
                            sur les serveurs utilisés. Chaque donnée que l’éditeur supprime est immédiatement et
                            définitivement effacée des serveurs. Aucune de ces données n’est conservée, ni revendue à
                            des tiers.<br/>
                            mag-iris se conforme en tout temps à la loi fédérale sur la protection des données et, dans
                            la mesure où il trouve application, au Règlement général européen sur la protection des
                            données.<br/>
                            Au surplus, les parties appliquent la politique de confidentialité édictée par mag-iris qui
                            fait l'objet d'un règlement séparé.<br/>
                        </Description>
                        <br/>
                        <Title>10. Responsabilité</Title>
                        <Description>
                            L’utilisation du site est faite sous la seule et entière responsabilité de
                            l’utilisateur.<br/>
                            En conséquence, l’éditeur ne peut en aucun cas être tenu pour responsable en cas de dommage,
                            direct ou indirect, de quelque nature que ce soit, résultant notamment de la perte de
                            clientèle, de données, de bien matériels ou immatériels.<br/>
                            Plus particulièrement, l’éditeur décline toute responsabilité concernant le fonctionnement
                            de logiciels ou de la transmission de toute donnée ou information sur internet, de même
                            qu’en cas de hacking de tiers qui causerait un dommage à un utilisateur ou à un tiers.<br/>
                            Le site et/ou l’application sont en principe accessibles à tout moment. Cependant, l’éditeur
                            décline toute responsabilité en cas d’impossibilité technique de connexion, qu’elle soit due
                            à un cas de force majeure, à une opération de maintenance, à une mise à jour éditoriale,
                            interruption ou panne de réseau, coupure d’électricité, défaillance, mauvaise configuration
                            ou mauvaise utilisation de l’ordinateur par l’utilisateur, ou de toute autre cause
                            dépendante ou non de la volonté du site.<br/>
                            Tout matériel qui serait téléchargé par un utilisateur dans le cadre de l’utilisation des
                            services l’est à ses risques et périls, l’éditeur déclinant toute responsabilité à l’égard
                            de tous dommages subis par l’utilisateur de ce fait.<br/>
                            L’éditeur prend toutes les mesures afin de prémunir son site et son application contre des
                            programmes malicieux (malware) ou virus. Cependant, l’éditeur ne peut garantir que ses
                            plateformes soit exemptes de tout programme malicieux ou virus. Il ne saurait être tenu
                            responsable du dommage causé par une contamination par un virus du matériel informatique de
                            l’utilisateur, auquel il incombe de prendre toutes les mesures préventives nécessaires.<br/>
                            L’éditeur se réserve le droit, à tout moment et sans préavis, d’interrompre temporairement
                            ou de façon permanente l’accès à tout ou partie du site, sans que cette interruption ne
                            donne droit à un quelconque dédommagement ou à un recours de la part d’un utilisateur ou
                            d’un tiers.<br/>
                        </Description>
                        <br/>
                        <Title>11. Droit applicable et for</Title>
                        <Description>
                            Les CGU sont soumise au droit suisse.<br/>
                            Le for exclusif de juridiction est à Genève, Suisse.<br/>
                        </Description>
                        <HeadingRow>
                            <Heading>Règles de confidentialité</Heading>
                        </HeadingRow>
                        <Title>1. Introduction</Title>
                        <Description>
                            Lorsque vous utilisez nos services, vous nous faites confiance pour le traitement de vos
                            données. Les présentes règles de confidentialité visent à vous indiquer quelles informations
                            nous collectons, pour quelle raison, et comment nous les utilisons. Ces règles sont
                            importantes et nous espérons que vous prendrez le temps de les lire attentivement.
                        </Description>
                        <br/>
                        <Title>2. Comment nous utilisons les données que nous collectons</Title>
                        <Description>
                            Les informations que nous collectons servent à améliorer les services proposés à tous nos
                            utilisateurs. Il peut s’agir d’informations de base, telles que la langue que vous utilisez,
                            ou plus complexes, comme les services que vous trouvez les plus utiles.<br/>
                            Les données que nous collectons nous permettent de fournir, gérer, protéger et améliorer nos
                            services, d’en développer de nouveaux, et de protéger aussi bien nos utilisateurs que
                            nous-mêmes. Ces données nous permettent également de vous proposer des contenus adaptés.
                        </Description>
                        <br/>
                        <Title>3. Informations que nous collectons via nos services</Title>
                        <Description>
                            Nous collectons des informations relatives aux services du site que vous renseignez afin de
                            nous contacter. Ces données nous permettent de vous contacter afin de vous offrir un conseil
                            personnalisé.<br/>
                            Les informations que nous collectons incluent vos coordonnées, nom et prénom, adresse,
                            localité, adresse e-mail et numéro de téléphone. Nous recueillons aussi des informations
                            relatives à la date, l’heure et le site de provenance de votre demande d’abonnement.
                        </Description>
                        <br/>
                        <Title>4. Transparence et liberté de choix</Title>
                        <Description>
                            Les préoccupations en matière de confidentialité diffèrent d’une personne à l’autre. Nous
                            souhaitons faire preuve de transparence sur la façon dont nous collectons et utilisons les
                            données qui vous concernent, afin que vous disposiez de tous les éléments pour faire des
                            choix informés. Vous pouvez par exemple si vous ne souhaitez plus recevoir de message de
                            notre part, effectuer les actions ci-dessous :<br/>
                            - Modifier vos préférences et critères associées à votre compte client.<br/>
                            - Supprimer toutes vos données associées à votre compte client de notre fichier.<br/>
                            Pour cela, il vous suffit juste de nous adresser un mail à : contact@mag-iris.com ou et nous
                            communiquer votre souhait.
                        </Description>
                        <br/>
                        <Title>5. Données que nous partageons</Title>
                        <Description>
                            Nous ne communiquons vos données personnelles à des entreprises, des organisations ou des
                            personnes tierces que dans les circonstances alternatives suivantes :<br/>
                            - Avec votre consentement<br/>
                            Nous ne communiquons des données personnelles vous concernant à des entreprises, des
                            organisations ou des personnes tierces qu’avec votre consentement. Nous demandons toujours
                            votre autorisation avant de communiquer à des tiers des données personnelles.<br/>
                            - Pour des besoins de traitement externe<br/>
                            Nous transmettons des données personnelles à nos partenaires ou autres sociétés ou personnes
                            de confiance qui les traitent pour notre compte, selon nos instructions, conformément aux
                            présentes Règles de confidentialité et dans le respect de toute autre mesure appropriée de
                            sécurité et de confidentialité.<br/>
                            - Pour des raisons juridiques<br/>
                            Nous ne partagerons des données personnelles avec des entreprises, des organisations ou des
                            personnes tierces que si nous pensons en toute bonne foi que l’accès, l’utilisation, la
                            protection ou la divulgation de ces données est raisonnablement justifiée pour :<br/>
                            - satisfaire à des obligations légales, réglementaires, à des procédures judiciaires ou à
                            des demandes gouvernementales ayant force exécutoire ;<br/>
                            - faire appliquer les conditions d’utilisation en vigueur, y compris pour constater
                            d’éventuels manquements à celles-ci ;<br/>
                            - déceler, éviter ou traiter des activités frauduleuses, les atteintes à la sécurité ou tout
                            problème d’ordre technique ;<br/>
                            - se prémunir contre toute atteinte aux droits, aux biens ou à notre sécurité, de ses
                            utilisateurs ou du public, en application et dans le respect de la loi.<br/>
                        </Description>
                        <br/>
                        <Title>11. Droit applicable et for</Title>
                        <Description>
                            Les présentes règles de confidentialité respectent la loi sur la protection des données
                            ainsi que le nouveau Règlement européen sur la protection des données (RGPD) mais restent
                            soumises au droit suisse.<br/>
                            Les présentes conditions générales sont soumises au droit suisse. Seul ce droit est
                            applicable pour l’interprétation du contrat et des conditions générales, ainsi que pour le
                            règlement de tout litige.<br/>
                            Le for exclusif de juridiction est à Genève, Suisse.<br/>
                        </Description>
                    </ContentWithPaddingXl>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        );
    }
}

export default withTranslation()(CGU);
