import "slick-carousel/slick/slick.css";
import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/love-illustration.svg";
import GreenStar from "images/green-star.png";
import {ReactComponent as ArrowLeftIcon} from "images/arrow-left-3-icon.svg";
import {ReactComponent as ArrowRightIcon} from "images/arrow-right-3-icon.svg";
import RondVertImage from "../../images/rond-vert.png";

const Row = tw.div`flex flex-col md:flex-row justify-between md:px-16`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative lg:pr-5`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const Image = tw.img``;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const ImageStar = tw.img`w-5 h-5`;
const ImageStarWrapper = tw.div`mr-1 last:mr-0 inline-block`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = tw.div``;
const TestimonialHeading = tw.div`mt-4 text-xl font-semibold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed italic text-black font-light opacity-75`;


const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0 items-end ml-auto mr-0`}
`;

const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 hover:bg-purple-pastel text-purple-pastel hover:text-white focus:outline-none border-solid border border-purple-pastel`}
  svg {
    ${tw`w-4 h-4 stroke-2`}
  }
`;

const RondVert = tw.img`w-128 absolute bottom-0 left-2 xl:left-5 ml-8 hidden lg:block`;

export default ({
                    imageSrc = loveIllustrationImageSrc,
                    imageRounded = false,
                    imageBorder = false,
                    imageShadow = false,
                    subheading = "Testimonials",
                    heading = "Our Clients Love Us.",
                    textOnLeft = false,
                    testimonials = []
                }) => {
    const [sliderRef, setSliderRef] = useState(null);

    return (
        <Container>
            <ContentWithPaddingXl>
                <Row>
                    <RondVert src={RondVertImage} style={{zIndex: -2}}/>
                    <ImageColumn>
                        <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow}
                               imageRounded={imageRounded}/>
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        {subheading}
                        {heading}
                        <TestimonialSlider
                            arrows={false}
                            ref={setSliderRef}
                            slidesToScroll={1}
                            autoplay={true}
                            autoplaySpeed={3000}
                            pauseOnHover={true}
                        >
                            {testimonials.map((testimonial, index) => (
                                <Testimonial key={index}>
                                    <CustomerInfo>
                                        <CustomerProfilePicture src={testimonial.profileImageSrc}
                                                                alt={testimonial.customerName}/>
                                        <CustomerTextInfo>
                                            <CustomerName>{testimonial.customerName}</CustomerName>
                                            <StarsContainer>
                                                {Array.from({length: testimonial.stars}).map((_, indexIcon) => (
                                                    <ImageStarWrapper key={indexIcon}><ImageStar
                                                        src={GreenStar}/></ImageStarWrapper>
                                                ))}
                                            </StarsContainer>
                                        </CustomerTextInfo>
                                    </CustomerInfo>
                                    <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                                    <Quote>{testimonial.quote}</Quote>
                                </Testimonial>
                            ))}
                        </TestimonialSlider>
                        <Controls>
                            <ControlButton onClick={sliderRef?.slickPrev}>
                                <ArrowLeftIcon/>
                            </ControlButton>
                            <ControlButton onClick={sliderRef?.slickNext}>
                                <ArrowRightIcon/>
                            </ControlButton>
                        </Controls>
                    </TextColumn>
                </Row>
            </ContentWithPaddingXl>
        </Container>
    );
};
