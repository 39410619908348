import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {animateScroll} from "react-scroll";
import {ReactComponent as ArrowUp} from "feather-icons/dist/icons/arrow-up.svg";
import {ReactComponent as ArrowDown} from "feather-icons/dist/icons/arrow-down.svg";

const FixedElements = tw.div`fixed w-0 lg:w-20 h-full z-20 tracking-widest`;

const FixedElementsRight = styled(FixedElements)`
  ${tw`right-0`}
`;

const PointContainer = tw.div`absolute top-1/2 right-0 pr-6 mt--20 invisible lg:visible`;
const PointIco = styled.div`
  ${tw`h-6 w-6 my-6 bg-green-classic rounded-full`}
  svg {
    ${tw`h-6 text-green-classic hover:text-white p-1`}
  }
`;

class GreenPoint extends React.Component {

    constructor(props) {
        super(props);
    }

    scrollToTop = () => {
        animateScroll.scrollToTop();
    }

    scrollMore = () => {
        animateScroll.scrollMore(900);
    }

    render() {
        return (
            <FixedElementsRight>
                <PointContainer>
                    <PointIco onClick={this.scrollToTop}>
                        <ArrowUp/>
                    </PointIco>
                    <PointIco onClick={this.scrollMore}>
                        <ArrowDown/>
                    </PointIco>
                </PointContainer>
            </FixedElementsRight>
        )
    }

}

export default GreenPoint;
