import React, {useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "./common/Header";
import Footer from "./common/Footer";
import * as axios from "axios";
import Constants from "../constants";
import {getLanguage} from "./common/languages";
import InfiniteScroll from "react-infinite-scroll-component";
import {withTranslation} from "react-i18next";
import GreenPoint from "./common/GreenPoint";
import SocialNetworks from "./common/SocialNetworks";
import {getImageUrl} from "../utils";

const HeadingRow = tw.div`flex`;
const Heading = tw.h1`font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-pastel leading-tight`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:border-t-2`} ${!props.left && tw`sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-black font-light`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center`}
`;
const ImageLeft = tw(Image)`sm:hidden`;
const ImageRight = tw(Image)`hidden sm:block`;
const Info = tw.div`p-8 border-2 border-t-0 border-purple-pastel`;
const CreationDate = tw.div`mt-1 uppercase text-black italic text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-black group-hover:text-purple-classic transition duration-300`;
const Description = tw.div`overflow-hidden h-56`;
const Loader = tw.div`w-1 mx-auto mt-8`;

class Blog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            page: 0,
            finished: false,
            limit: 10
        }
    }

    componentDidMount() {
        this.loadArticles();
    }

    loadArticles = () => {
        const currentArticles = this.state.articles;

        const dateOption = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };

        axios.get(Constants.baseCmsUrl + Constants.articlesRoute + "?lang=" + getLanguage().toUpperCase() + "&_sort=publish_date:DESC&_start=" + this.state.page + "&_limit=" + this.state.limit)
            .then((response) => {
                const articles = response.data.map(article => {
                    //TODO default image
                    let imgUrl = "https://images.unsplash.com/photo-1552641156-93ea2a5f78e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80";
                    if (article.images.length > 0) {
                        imgUrl = getImageUrl(article.images[0], 'medium');
                    }

                    return {
                        imageSrc: imgUrl,
                        date: new Date(article.publish_date).toLocaleDateString(getLanguage().toUpperCase(), dateOption),
                        title: article.title,
                        description: article.content,
                        url: Constants.websiteUrl + "/blog/" + article.id,
                        featured: true
                    }
                });
                const newArticles = currentArticles.concat(articles);
                const newPage = this.state.page + 1;
                const newFinished = articles.length !== this.state.limit;
                this.setState({articles: newArticles, page: newPage, finished: newFinished});
            })
            .catch(err => console.error(err));
    }

    render() {

        return (
            <AnimationRevealPage>
                <GreenPoint animation={false}/>
                <SocialNetworks showScrollButton={false} animation={false}/>
                <Header animation={false}/>
                <Container animation={false}>
                    <ContentWithPaddingXl>
                        <HeadingRow>
                            <Heading>Le blog d'Iris</Heading>
                        </HeadingRow>
                        <Posts>
                            <InfiniteScroll
                                next={this.loadArticles}
                                hasMore={!this.state.finished}
                                loader={
                                    <Loader>
                                        <div className="lds-ripple purple">
                                            <div/>
                                            <div/>
                                        </div>
                                    </Loader>
                                }
                                dataLength={this.state.articles.length}
                            >
                                {this.state.articles.map((post, index) => (
                                    <PostContainer key={index} left={index % 2 === 1}>
                                        <Post className="group" as="a" href={post.url}>
                                            {index % 2 === 0 && <Image imageSrc={post.imageSrc}/>}
                                            {index % 2 === 1 && <ImageLeft imageSrc={post.imageSrc}/>}
                                            <Info>
                                                <CreationDate>{post.date}</CreationDate>
                                                <Title>{post.title}</Title>
                                                {post.featured && post.description &&
                                                <Description dangerouslySetInnerHTML={{__html: post.description}}/>
                                                }
                                            </Info>
                                            {index % 2 === 1 && <ImageRight imageSrc={post.imageSrc}/>}
                                        </Post>
                                    </PostContainer>
                                ))}
                            </InfiniteScroll>
                        </Posts>
                    </ContentWithPaddingXl>
                </Container>
                <Footer animation={false}/>
            </AnimationRevealPage>
        );
    }
}

export default withTranslation()(Blog);
