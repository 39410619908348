import React, {useState} from "react";
import {motion} from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";
import {SectionDescription} from "components/misc/Typography.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts.js";
import {ReactComponent as ChevronDownIcon} from "feather-icons/dist/icons/chevron-down.svg";

const Subheading = tw.div`text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-3 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 text-black hover:text-gray-900 bg-green-pastel hover:bg-green-classic transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-lg`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed font-light`);

export default ({
                    subheading = "FAQS",
                    heading = "You have Questions ?",
                    description = "And we have got answers to all of them. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    faqs = [
                        {
                            question: "Is lunch provided free of cost ?",
                            answer:
                                "Yes, it is, if you have a membership with us. Otherwise it is charged as per the menu. Some limits do apply as to how much items can be included in your lunch. This limit is enough for any one person and merely exists to discourage abusal of the system."
                        },
                        {
                            question: "Do you have 2 Bedroom suites ?",
                            answer:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        },
                        {
                            question: "Are Wi-Fi costs included in the price ?",
                            answer:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        },
                        {
                            question: "Where can I reach you for support ?",
                            answer:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        }
                    ]
                }) => {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const toggleQuestion = questionIndex => {
        if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
        else setActiveQuestionIndex(questionIndex);
    };

    return (
        <Container>
            <ContentWithPaddingXl>
                <Column>
                    <HeaderContent>
                        <Subheading>{subheading}</Subheading>
                        {heading}
                        {description}
                    </HeaderContent>
                    <FAQSContainer>
                        {faqs.map((faq, index) => (
                            <FAQ
                                key={index}
                                onClick={() => {
                                    toggleQuestion(index);
                                }}
                                className="group"
                            >
                                <Question>
                                    <QuestionText>{faq.question}</QuestionText>
                                    <QuestionToggleIcon
                                        variants={{
                                            collapsed: {rotate: 0},
                                            open: {rotate: -180}
                                        }}
                                        initial="collapsed"
                                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                        transition={{duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98]}}
                                    >
                                        <ChevronDownIcon/>
                                    </QuestionToggleIcon>
                                </Question>
                                <Answer
                                    variants={{
                                        open: {opacity: 1, height: "auto", marginTop: "16px"},
                                        collapsed: {opacity: 0, height: 0, marginTop: "0px"}
                                    }}
                                    initial="collapsed"
                                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                    transition={{duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98]}}
                                    dangerouslySetInnerHTML={{__html: faq.answer}}
                                >
                                </Answer>
                            </FAQ>
                        ))}
                    </FAQSContainer>
                </Column>
            </ContentWithPaddingXl>
        </Container>
    );
};
