export default {
    websiteUrl: process.env.REACT_APP_WEBSITE_URL,
    baseUrl: process.env.REACT_APP_API_URL,
    baseCmsUrl: process.env.REACT_APP_CMS_URL,
    privateAppUrl: process.env.REACT_APP_APP_PRIVATE_URL,
    opinionRoute: "/opinions",
    articlesRoute: "/articles",
    partnersRoute: "/partners",
    contactRoute: "/api/core/contact/",
    newsletterRoute: "/api/core/contact/newsletter",
    hcaptchaKey: process.env.REACT_APP_HCAPTCHA_KEY,
    social: {
        linkedin: "https://www.linkedin.com/company/mag-iris/",
        facebook: "https://www.facebook.com/Mag-Iris-102053205013946/",
        instagram: "https://www.instagram.com/mag_iris/"
    }
}
