import i18next from "i18next";

export function getLanguage() {
    let lang = i18next.language || window.localStorage.i18nextLng;
    const langCode = lang.substr(0, 2);
    if (languages[langCode] === undefined) {
        return 'fr';
    }
    return langCode;
}

export const languages = {'fr': "français", 'it': "italiano", 'de': "deutsch"};
