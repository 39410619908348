const formats = ["large", "medium", "small", "thumbnail"];

export function getImageUrl(image, desired) {

    if (image.formats === null) {
        return image.url;
    }

    if (image.formats[desired] !== undefined) {
        return image.formats[desired].url;
    }

    const format = formats.find(obj => image.formats[obj] !== undefined);

    if (format !== undefined) {
        return image.formats[format].url;
    }

    return "";
}
