import React from "react";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";
import styled from "styled-components";

import formula1 from "../images/magiris-formule_x1.png";
import formula2 from "../images/magiris-formule_x6.png";
import formula3 from "../images/magiris-formule_x12.png";
import formula4 from "../images/magiris-formule_x12G.png";

export const Container = styled.div`
     ${tw`lg:flex`}

     .formula-0 {
          ${tw`border-green-pastel hover:bg-green-pastel`}
          .formulaType {
               ${tw`text-green-classic`}
          }
          .sub {
               ${tw`bg-green-pastel`}
          }
     }
     
     .formula-1 {
          ${tw`border-green-classic hover:bg-green-pastel`}
          .formulaType {
               ${tw`text-green-classic`}
          }
          .sub {
               ${tw`bg-green-pastel`}
          }
     }     
     
     .formula-2 {
          ${tw`border-purple-pastel bg-purple-pastel hover:bg-purple-classic text-white`}
          .formulaType {
               ${tw`text-white`}
          }
          .sub {
               ${tw`bg-white text-purple-pastel`}
          }
          button {
             ${tw`bg-purple-pastel`}
          }
     }
     
     .formula-3 {
          ${tw`border-purple-classic hover:bg-green-pastel`}
          .formulaType {
               ${tw`text-purple-pastel`}
          }
          .price {
               ${tw`text-2xl mt-12`}
          }
          .link {
               ${tw`uppercase text-base font-light text-green-classic underline`}
          }
          .sub {
               ${tw`bg-purple-pastel text-white`}
          }
     }
     
`;
export const SignupFormulaWrapper = styled.div`
     ${tw`border-4 rounded-2xl flex-1 flex flex-col justify-center items-center text-center cursor-pointer mx-2 mt-8 lg:mt-0`}
`;
export const SignupFormulaType = tw.span`uppercase my-5 text-sm h-10`;
export const SignupFormulaIcon = tw.img`h-16 ml-3`;
export const SignupPriceWrapper = tw.div`h-32`;
export const SignupFormulaPrice = tw.div`text-5xl font-medium mt-5`;
export const SignupFormulaCurrency = tw.div`font-light text-lg`;
export const SignupFormulaSub = tw.div`uppercase font-medium mt-8 px-5 py-2 rounded-full mx-3 mb-8`;
export const SignupFormulaTextWrapper = tw.div`h-32`;
export const SignupFormulaText = tw.p`text-xs font-light mb-3 mx-2`;
export const SignupFormulaButton = tw.button`bottom-0 opacity-0 group-hover:opacity-100 bg-green-classic text-white mb--5 p-1 uppercase px-5 rounded-full`;


class Formulas extends React.Component {

    constructor(props) {
        super(props);

        const {t} = props;

        this.formulas = [
            {
                formulaType: t("HOME_B5_TYPE_ONE"),
                image: formula1,
                price: "17.-",
                currency: "CHF",
                subInfo: t("HOME_B5_F1_SUB"),
                text1: t("HOME_B5_F1_TEXT1"),
                text2: t("HOME_B5_F1_TEXT2"),
                onClick: this.props.openSignup,
                buttonEnabled: true
            },
            {
                formulaType: t("HOME_B5_TYPE_ONE"),
                image: formula2,
                price: "90.-",
                currency: "CHF",
                subInfo: t("HOME_B5_F2_SUB"),
                text1: t("HOME_B5_F2_TEXT1"),
                text2: t("HOME_B5_F2_TEXT2"),
                onClick: this.props.openSignup,
                buttonEnabled: true
            },
            {
                formulaType: t("HOME_B5_TYPE_ONE"),
                image: formula3,
                price: "170.-",
                currency: "CHF",
                subInfo: t("HOME_B5_F3_SUB"),
                text1: t("HOME_B5_F3_TEXT1"),
                text2: t("HOME_B5_F3_TEXT2"),
                onClick: this.props.openSignup,
                buttonEnabled: true
            },
            {
                formulaType: t("HOME_B5_TYPE_MANY"),
                image: formula4,
                price: t("HOME_B5_F4_PRICE") + "<div class='link'>" + t("HOME_B5_F4_SUBPRICE") + "</div>",
                currency: "",
                subInfo: t("HOME_B5_F4_SUB"),
                text1: t("HOME_B5_F4_TEXT1"),
                text2: t("HOME_B5_F4_TEXT2"),
                onClick: this.props.openContact,
                buttonEnabled: false
            }
        ]
    }

    render() {

        const {t} = this.props;

        return (
            <Container>
                {
                    this.formulas.map((formula, idx) => {
                        return (
                            <SignupFormulaWrapper
                                key={idx}
                                className={"formula-" + idx + " group"}
                                onClick={formula.onClick}>
                                <SignupFormulaType className={"formulaType"}>{formula.formulaType}</SignupFormulaType>
                                <SignupFormulaIcon src={formula.image}/>
                                <SignupPriceWrapper>
                                    <SignupFormulaPrice className={"price"}
                                                        dangerouslySetInnerHTML={{__html: formula.price}}/>
                                    <SignupFormulaCurrency>{formula.currency}</SignupFormulaCurrency>
                                </SignupPriceWrapper>
                                <SignupFormulaSub className={"sub"}>{formula.subInfo}</SignupFormulaSub>
                                <SignupFormulaTextWrapper>
                                    <SignupFormulaText>{formula.text1}</SignupFormulaText>
                                    <SignupFormulaText>{formula.text2}</SignupFormulaText>
                                </SignupFormulaTextWrapper>
                                {
                                    formula.buttonEnabled && <SignupFormulaButton>
                                        {t("HOME_B5_SUBSCRIBE")}
                                    </SignupFormulaButton>
                                }
                            </SignupFormulaWrapper>
                        )
                    })
                }
            </Container>
        )
    }
}

export default withTranslation()(Formulas)
